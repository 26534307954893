import { Avatar, Button, Popover, Spacer } from '@vapor/ui';
import { styled } from 'buttered';
import { useIsLoggedIn, useUser } from '../../state/user';
import { UserPopover } from './userPopover';

let Wrapper = styled('div')`
  display: flex;
`;

let AvatarWrapper = styled('figure')`
  height: 30px;
  width: 30px;
`;

export let User = () => {
  let { loggedIn, loading } = useIsLoggedIn();
  let { user } = useUser(true);

  return (
    <Wrapper>
      {loggedIn || loading ? (
        <Popover
          mobile
          popover={({ state: { close } }) => <UserPopover user={user} close={close} />}
        >
          {({ ref, attrs }) => (
            <AvatarWrapper ref={ref} {...attrs}>
              <Avatar size={30} src={user?.photoUrl} name={user?.name || 'Loading...'} />
            </AvatarWrapper>
          )}
        </Popover>
      ) : (
        <>
          <div className="desktop">
            <a href="/auth/login">
              <Button variant="secondary" size="small" as="div">
                Login
              </Button>
            </a>

            <Spacer width={10} />

            <a href="/auth/signup">
              <Button variant="primary" size="small" as="div">
                Signup
              </Button>
            </a>
          </div>
        </>
      )}
    </Wrapper>
  );
};
