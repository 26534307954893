import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { get, removeCookie } from 'tiny-cookie';
import { IUser } from './interfaces/user';
import { fetcher } from './sdk/client';

let COOKIE_NAME = 'token';

let hasToken = typeof window == 'undefined' ? undefined : !!get(COOKIE_NAME);

export let getApiToken = () => get(COOKIE_NAME);
export let deleteApiToken = () => removeCookie(COOKIE_NAME);

export let useUser = (canBeUnauthenticated = false) => {
  let { data, error, isValidating } = useSWR<{
    user: IUser;
    teams: { id: string; name: string; calendars: { id: string; name: string }[] }[];
  }>(['/boot', canBeUnauthenticated], fetcher);

  return {
    user: data?.user,
    teams: data?.teams,
    error,
    loading: !data && !error && isValidating
  };
};

export let useIsLoggedIn = () => {
  let { user, loading: userLoading, error: userError } = useUser(true);

  let [loading, setLoading] = useState(false);
  let [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (typeof window == 'undefined') return;

    if (userError) {
      setLoggedIn(false);
      setLoading(false);
    } else if (user) {
      setLoading(false);
      setLoggedIn(true);
    } else if (hasToken) {
      setLoggedIn(true);
      setLoading(true);
    } else {
      setLoggedIn(false);
      setLoading(false);
    }
  }, [user?.id, userError, userLoading]);

  return { loading, loggedIn };
};
