import { styled } from 'buttered';
import React, { useEffect, useState } from 'react';
import { Footer } from '../components/footer';
import { Nav } from './nav';

let Wrapper = styled('div')`
  min-height: 100%;
  width: 100vw;
  overflow-x: hidden;
`;

let Header = styled('header')`
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 1000;
`;

let Main = styled('main')`
  /* min-height: calc(100vh - 61px); */
`;

export let BaseLayout = ({
  children,
  alwaysShowNavbar
}: {
  children: React.ReactNode;
  alwaysShowNavbar?: boolean;
}) => {
  let [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    let handler = () => {
      setScrolled(window.scrollY > 0);
    };

    handler();
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  return (
    <>
      <Wrapper>
        <Header>
          <Nav scrolled={scrolled || alwaysShowNavbar} />
        </Header>

        <Main>
          <div>{children}</div>
        </Main>
      </Wrapper>

      <Footer />
    </>
  );
};
