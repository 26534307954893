import { Button, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { keyframes, styled } from 'buttered';
import clsx from 'clsx';
import Link from 'next/link';
import { useState } from 'react';
import { Menu, X } from 'react-feather';
import useDelayed from 'use-delayed';
import { Logo } from '../../components/logo';
import { useIsLoggedIn } from '../../state/user';
import { User } from './user';

let Wrapper = styled('nav')`
  padding: 15px 20px;
  z-index: 5;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: all 0.3s;
  border-bottom: solid transparent 1px;
  color: black;

  &.scrolled {
    border-bottom: var(--vapor-border);
    background: rgba(255, 255, 255, 0.9);
    color: black;
  }
`;

let fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-15px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

let fadeOut = keyframes`
 from {


    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-15px);
  }
`;

let Inner = styled('div')`
  display: flex;
  max-width: 1050px;
  margin: 0px auto;

  .desktop {
    display: flex;

    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  .mobile {
    display: flex;

    @media screen and (min-width: 720px) {
      display: none;
    }
  }
`;

let Left = styled('div')`
  display: flex;
  flex-grow: 1;
`;

let Right = styled('div')`
  display: flex;
`;

let LogoWrapper = styled('figure')`
  display: flex;
`;

let MobileNav = styled('div')`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 1020;
  display: flex;
  transition: all 0.3s;

  @media screen and (min-width: 720px) {
    display: none;
  }
`;

let MobileNavInner = styled('nav')`
  border: var(--vapor-border);
  background: var(--vapor-background);
  flex-grow: 1;
  height: fit-content;

  &.show {
    animation: ${fadeIn} 0.5s forwards;
  }

  &.hide {
    animation: ${fadeOut} 0.5s forwards;
  }
`;

let MobileNavHeader = styled('header')`
  border-bottom: var(--vapor-border);
  display: flex;
  padding: 20px;
`;

let MobileNavContent = styled('main')`
  padding: 25px 20px;
`;

export let Nav = ({ scrolled }: { scrolled?: boolean }) => {
  let [mobileNavOpen, setMobileNavOpen] = useState(false);
  let delayedMobileNavOpen = useDelayed(mobileNavOpen, 300, [true]);
  let { loggedIn } = useIsLoggedIn();

  let goToPricing = () => {
    setMobileNavOpen(false);

    let pricingEl = document.getElementById('pricing');

    if (!pricingEl) {
      window.location.href = '/#pricing';
      return;
    }

    window.scrollTo({
      top: pricingEl.getBoundingClientRect().top + window.scrollY - 60,
      behavior: 'smooth'
    });
  };

  return (
    <Wrapper className={clsx({ scrolled })}>
      <Inner>
        <Left>
          <div className="mobile">
            <Button
              size="small"
              aria-label="Open Navigation"
              icon={<Menu />}
              variant="tertiary"
              onClick={() => setMobileNavOpen(true)}
            />

            <Spacer width={10} />
          </div>

          <Link href="/">
            <a style={{ display: 'flex', color: 'unset' }}>
              <LogoWrapper>
                <Logo />

                <Spacer width={10} />

                <div>
                  <VerticalCenter>
                    <Text size={18} as="h1" weight={600} color="inherit">
                      Weekrise
                    </Text>
                  </VerticalCenter>
                </div>
              </LogoWrapper>
            </a>
          </Link>
        </Left>

        <Right>
          <div className="desktop">
            <Button size="small" onClick={goToPricing}>
              <strong style={{ fontWeight: 500, color: 'black' }}>Pricing</strong>
            </Button>

            <Spacer width={10} />

            <Link href="/academy">
              <a>
                <Button size="small" as="div">
                  <strong style={{ fontWeight: 500, color: 'black' }}>Academy</strong>
                </Button>
              </a>
            </Link>

            {loggedIn && (
              <>
                <Spacer width={10} />

                <a href="/app">
                  <Button size="small" variant="primary" as="div">
                    Open Launchpad
                  </Button>
                </a>
              </>
            )}
          </div>

          <Spacer width={10} />

          <User />
        </Right>
      </Inner>

      {delayedMobileNavOpen && (
        <MobileNav
          onClick={() => setMobileNavOpen(false)}
          style={{
            opacity: mobileNavOpen ? 1 : 0
          }}
        >
          <MobileNavInner
            onClick={e => e.stopPropagation()}
            className={clsx({
              show: mobileNavOpen,
              hide: !mobileNavOpen
            })}
          >
            <MobileNavHeader>
              <VerticalCenter>
                <LogoWrapper>
                  <Logo />

                  <Spacer width={10} />

                  <div>
                    <VerticalCenter>
                      <Text size={16} as="h1" weight={500}>
                        Weekrise
                      </Text>
                    </VerticalCenter>
                  </div>
                </LogoWrapper>
              </VerticalCenter>

              <Spacer />

              <Button
                size="small"
                aria-label="Close Navigation"
                icon={<X />}
                variant="tertiary"
                onClick={() => setMobileNavOpen(false)}
              />
            </MobileNavHeader>

            <MobileNavContent>
              <Button variant="secondary" fullWidth onClick={goToPricing}>
                Pricing
              </Button>

              <Spacer height={10} />

              <Link href="/academy">
                <a>
                  <Button variant="secondary" as="div">
                    Academy
                  </Button>
                </a>
              </Link>

              <Spacer height={10} />

              {loggedIn ? (
                <>
                  <Link href="/app">
                    <a>
                      <Button variant="primary" as="div">
                        Open Launchpad
                      </Button>
                    </a>
                  </Link>
                </>
              ) : (
                <>
                  <Link href="/auth/login">
                    <a>
                      <Button as="div" variant="secondary">
                        Login
                      </Button>
                    </a>
                  </Link>

                  <Spacer height={10} />

                  <Link href="/auth/signup">
                    <a>
                      <Button variant="primary" as="div">
                        Signup
                      </Button>
                    </a>
                  </Link>
                </>
              )}
            </MobileNavContent>
          </MobileNavInner>
        </MobileNav>
      )}
    </Wrapper>
  );
};
