let config = {
  api: 'https://athena.weekrise.com',

  paddle:
    process.env.NODE_ENV == 'production'
      ? {
          vendorId: 152588,
          isSandbox: false,
          proMonthlyId: 781270,
          teamMonthlyId: 781271
        }
      : {
          vendorId: 6058,
          isSandbox: true,
          proMonthlyId: 28432,
          teamMonthlyId: 32790
        }
};

if (process.env.NODE_ENV != 'production') {
  config.api = 'http://localhost:3102';
}

export { config };
