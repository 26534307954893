import { Avatar, Button, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import { IUser } from '../../state/interfaces/user';

let Wrapper = styled('div')`
  margin: 0px 5px;
`;

let Box = styled('div')`
  padding: 20px;
  text-align: center;
`;

let Divider = styled('hr')`
  border: none;
  height: 1px;
  background: #efefef;
  margin: 0px;
  padding: 0px;
`;

let Link = styled('a')`
  display: block;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

let AvatarWrapper = styled('div')`
  width: fit-content;
  margin: 0px auto;
`;

export let UserPopover = ({ user, close }: { user?: IUser; close: () => void }) => {
  if (!user) return null;

  return (
    <Wrapper>
      <Box>
        <AvatarWrapper>
          <Avatar name={user.name} size={100} src={user.photoUrl} />
        </AvatarWrapper>

        <Spacer height="15px" />

        <Text size={18} weight={500}>
          {user.name}
        </Text>

        <Spacer height="5px" />

        <Text size={12} color="var(--vapor-accent-6)">
          {user.email}
        </Text>
      </Box>

      <Divider />

      <Box style={{ padding: 10, marginTop: 5 }}>
        <Button
          size="small"
          fullWidth
          variant="tertiary"
          onClick={() => {
            window.location.href = '/app/account';
            close();
          }}
        >
          Manage Account
        </Button>
        <Spacer height={15} />
        <Button
          size="small"
          fullWidth
          variant="tertiary"
          onClick={() => {
            window.location.href = '/app';
            close();
          }}
        >
          Open Launchpad
        </Button>
        <Spacer height={15} />
        <Button
          size="small"
          fullWidth
          variant="tertiary"
          onClick={() => {
            window.location.href = '/auth/logout';
            close();
          }}
        >
          Logout
        </Button>
      </Box>
    </Wrapper>
  );
};
